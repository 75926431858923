import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./App.css";
import BottomNavbar from "./components/BottomNavbar";
function App() {
  return (
    <div className="App">
      <Header />
      <main className="pb-3">
        <>
          {/* <HomeScreen /> */}
          <Outlet />
        </>
      </main>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
      />
      <BottomNavbar />
    </div>
  );
}

export default App;
