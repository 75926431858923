/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Message from "../components/Message"; // Message component for error state
import PaginateComponent from "../components/Paginate"; // Ensure you have a Paginate component
import ProductGrid from "../components/ProductGrid"; // Ensure you have a Product component
//import { fetchBrands } from "../api"; // Import API function to fetch brands

const ProductListPage = ({ products, keyword }) => {
  const [sortOrder, setSortOrder] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(products || []);
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000 });
  const [selectedRating, setSelectedRating] = useState("");

  // Fetch brands from backend
  useEffect(() => {
    const fetchBrandsData = async () => {
      const brandData = ["apple", "xiomi"]; // Assume this function fetches brand data
      setBrands(brandData);
    };
    fetchBrandsData();
  }, []);

  // Handle sorting logic
  // useEffect(() => {
  //   let sortedProducts = [...filteredProducts];
  //   if (sortOrder === "priceLowToHigh") {
  //     sortedProducts.sort((a, b) => a.price - b.price);
  //   } else if (sortOrder === "priceHighToLow") {
  //     sortedProducts.sort((a, b) => b.price - a.price);
  //   }
  //   setFilteredProducts(sortedProducts);
  // }, [sortOrder, filteredProducts]);

  // Filter products based on selected brands, price, and ratings
  useEffect(() => {
    let tempProducts = products || [];

    // Filter by brand
    if (selectedBrands.length > 0) {
      tempProducts = tempProducts.filter((product) =>
        selectedBrands.includes(product.brand)
      );
    }

    // Filter by price
    tempProducts = tempProducts.filter(
      (product) =>
        product.price >= priceRange.min && product.price <= priceRange.max
    );

    // Filter by rating
    if (selectedRating) {
      const minRating = parseInt(selectedRating, 10);
      tempProducts = tempProducts.filter(
        (product) => product.rating >= minRating
      );
    }

    setFilteredProducts(tempProducts);
  }, [products, selectedBrands, priceRange, selectedRating]);

  return (
    <Row>
      {/* Sidebar for Filters */}
      <Col md={3} className="bg-light p-3">
        <h5>Filters</h5>
        {/* Price Filter */}
        <div className="filter-section mb-3">
          <h6>Price</h6>
          <Form.Control
            type="number"
            placeholder="Min"
            value={priceRange.min}
            onChange={(e) =>
              setPriceRange({ ...priceRange, min: Number(e.target.value) })
            }
            className="mb-2"
          />
          <Form.Control
            type="number"
            placeholder="Max"
            value={priceRange.max}
            onChange={(e) =>
              setPriceRange({ ...priceRange, max: Number(e.target.value) })
            }
            className="mb-3"
          />
        </div>

        {/* Brand Filter */}
        <div className="filter-section mb-3">
          <h6>Brand</h6>
          {brands.map((brand) => (
            <Form.Check
              key={brand}
              type="checkbox"
              label={brand}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedBrands([...selectedBrands, brand]);
                } else {
                  setSelectedBrands(selectedBrands.filter((b) => b !== brand));
                }
              }}
            />
          ))}
        </div>

        {/* Customer Ratings Filter */}
        <div className="filter-section mb-3">
          <h6>Customer Ratings</h6>
          <Form.Check
            type="radio"
            label="3★ & above"
            value="3"
            checked={selectedRating === "3"}
            onChange={(e) => setSelectedRating(e.target.value)}
          />
          <Form.Check
            type="radio"
            label="4★ & above"
            value="4"
            checked={selectedRating === "4"}
            onChange={(e) => setSelectedRating(e.target.value)}
          />
          <Button
            variant="link"
            className="p-0"
            onClick={() => setSelectedRating("")}
          >
            Clear all
          </Button>
        </div>
      </Col>

      {/* Product Grid */}
      <Col md={9}>
        {/* Sorting Dropdown */}
        <Row className="justify-content-end mb-3">
          <Col md={4}>
            <Form.Select
              aria-label="Sort Products"
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="">Sort by</option>
              <option value="priceLowToHigh">Price: Low to High</option>
              <option value="priceHighToLow">Price: High to Low</option>
            </Form.Select>
          </Col>
        </Row>

        {/* Product Grid */}
        <Row className="mt-2">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product, index) => (
              <Col key={index} sm={12} md={6} lg={4} xl={3} className="mb-1">
                <ProductGrid product={product} />
              </Col>
            ))
          ) : (
            <Message variant="info">No products found</Message>
          )}
        </Row>

        {/* Pagination */}
        <PaginateComponent
          page={products?.page}
          pages={products?.pages}
          keyword={keyword ? keyword : ""}
        />
      </Col>
    </Row>
  );
};

export default ProductListPage;
