import React from "react";
import { Navbar } from "react-bootstrap";
import {
  AiOutlineBook,
  AiOutlineHome,
  AiOutlineShoppingCart,
  AiOutlineUser,
} from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";

const BottomNavbar = () => {
  const location = useLocation();
  // console.log("location", location);
  const getActiveClass = (path) => {
    return location.pathname === path ? "activeLink" : "";
  };

  return (
    <Navbar
      fixed="bottom"
      className="justify-content-around navbar-glassmorph d-lg-none"
      style={{ borderTop: "1px solid grey" }}
    >
      <Link
        to="/"
        className={`nav-link d-flex flex-column align-items-center ${getActiveClass(
          "/"
        )}`}
      >
        <AiOutlineHome size={24} />
        <span className="small">Home</span>
      </Link>
      <Link
        to="/profile"
        className={`nav-link d-flex flex-column align-items-center ${getActiveClass(
          "/profile"
        )}`}
      >
        <AiOutlineUser size={24} />
        <span className="small">Profile</span>
      </Link>
      <Link
        to="/cart"
        className={`nav-link d-flex flex-column align-items-center ${getActiveClass(
          "/cart"
        )}`}
      >
        <AiOutlineShoppingCart size={24} />
        <span className="small">Cart</span>
      </Link>
      <Link
        to="/bookmarks"
        className={`nav-link d-flex flex-column align-items-center ${getActiveClass(
          "/bookmarks"
        )}`}
      >
        <AiOutlineBook size={24} />
        <span className="small">Bookmarks</span>
      </Link>
    </Navbar>
  );
};

export default BottomNavbar;
