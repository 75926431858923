import React from "react";
import { ProgressBar } from "react-bootstrap";

const ProgressTracker = ({ currentStep, step1Text, step2Text, totalSteps }) => {
  // Total number of steps

  return (
    <div className="progress-tracker d-flex flex-column align-items-center">
      <div className="progress-steps d-flex justify-content-between w-100">
        <div
          className={`step ${currentStep >= 1 ? "active" : ""}`}
          style={{
            whiteSpace: "nowrap",
            fontWeight: currentStep >= 1 ? "bold" : "normal",
            color: currentStep >= 1 ? "#007bff" : "#a9a9a9", // Highlight the current step
          }}
        >
          {step1Text ? step1Text : "Register"}
        </div>
        <div
          className={`step ${currentStep >= 2 ? "active" : ""}`}
          style={{
            whiteSpace: "nowrap",
            fontWeight: currentStep >= 2 ? "bold" : "normal",
            color: currentStep >= 2 ? "#007bff" : "#a9a9a9", // Highlight the current step
          }}
        >
          {step2Text ? step2Text : "Verification"}
        </div>
      </div>
      <ProgressBar
        now={(currentStep / totalSteps) * 100}
        className="w-100 mt-2"
      />
    </div>
  );
};

export default ProgressTracker;
