import React, { useState } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useCreateProductMutation } from "../../slices/productsApiSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddProductScreen = () => {
  const navigate = useNavigate();
  const [createProducts, { isLoading }] = useCreateProductMutation();

  const [productData, setProductData] = useState({
    entity_id: "", // Seller ID
    product_id: "", // Product ID
    category: "",
    productName: "",
    details: "",
    price: 0,
    stock_quantity: 0,
    currency_mode: "INR", // Default to INR
    estimated_delivery_time: 0,
    can_be_returned: true,
    estimated_return_pickup_time: 0,
    can_be_replaced: true,
    estimated_replacement_time: 0,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProductData({
      ...productData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createProducts(productData).unwrap();
      toast.success("Product added successfully!");
      navigate("/admin/products");
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={8}>
          <h1 className="my-4 text-center">Add New Product</h1>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="entity_id">
                  <Form.Label>Seller ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="entity_id"
                    value={productData.entity_id}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="product_id">
                  <Form.Label>Product ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="product_id"
                    value={productData.product_id}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="category">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    type="text"
                    name="category"
                    value={productData.category}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="productName">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="productName"
                    value={productData.productName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="price">
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    value={productData.price}
                    onChange={handleChange}
                    required
                    min="0"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="stock_quantity">
                  <Form.Label>Stock Quantity</Form.Label>
                  <Form.Control
                    type="number"
                    name="stock_quantity"
                    value={productData.stock_quantity}
                    onChange={handleChange}
                    required
                    min="0"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="currency_mode">
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    as="select"
                    name="currency_mode"
                    value={productData.currency_mode}
                    onChange={handleChange}
                    required
                  >
                    <option value="INR">INR</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="estimated_delivery_time">
                  <Form.Label>Estimated Delivery Time (days)</Form.Label>
                  <Form.Control
                    type="number"
                    name="estimated_delivery_time"
                    value={productData.estimated_delivery_time}
                    onChange={handleChange}
                    min="0"
                  />
                </Form.Group>
              </Col>
            </Row>
           
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="estimated_return_pickup_time">
                  <Form.Label>Estimated Return Pickup Time (days)</Form.Label>
                  <Form.Control
                    type="number"
                    name="estimated_return_pickup_time"
                    value={productData.estimated_return_pickup_time}
                    onChange={handleChange}
                    min="0"
                    disabled={!productData.can_be_returned}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="estimated_replacement_time">
                  <Form.Label>Estimated Replacement Time (days)</Form.Label>
                  <Form.Control
                    type="number"
                    name="estimated_replacement_time"
                    value={productData.estimated_replacement_time}
                    onChange={handleChange}
                    min="0"
                    disabled={!productData.can_be_replaced}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="can_be_returned">
                  <Form.Check
                    type="checkbox"
                    name="can_be_returned"
                    label="Can be returned"
                    checked={productData.can_be_returned}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="can_be_replaced">
                  <Form.Check
                    type="checkbox"
                    name="can_be_replaced"
                    label="Can be replaced"
                    checked={productData.can_be_replaced}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group controlId="details">
                  <Form.Label>Details</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="details"
                    value={productData.details}
                    onChange={handleChange}
                    required
                    rows={4}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              type="submit"
              variant="primary"
              className="w-100"
              disabled={isLoading}
            >
              {isLoading ? "Adding..." : "Add Product"}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AddProductScreen;
