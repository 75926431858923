import React, { useState } from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setUserEmail } from "../slices/authSlice";
import {
  useSentSellerRegistationEmailMutation,
  useVerifySellerOtpMutation,
} from "../slices/sellerApiSlice";
import {
  useForgotPasswordMailMutation,
  useRegisterMutation,
  useResetPasswordMutation,
  useSentRegistationEmailMutation,
  useVerifyForgotOtpMutation,
  useVerifyOtpMutation,
} from "../slices/usersApiSlice";
import { handleApiRequest } from "../utils/helper";
import { EmailStep } from "./EmailStep";
import { OtpStep } from "./OtpStep";
import FinalStep from "../components/FinalStep";
// Final Form Step Component

// Main MultiStepForm Component
const MultiStepForm = ({ finalStepComponent, finalStepProps }) => {
  const { type } = finalStepProps;
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state?.auth?.email);

  const [verifyOtp, { isLoading: verifyLoading }] = useVerifyOtpMutation();
  const [verifyForgotOtp, { isLoading: verifyForgotOtpLoading }] =
    useVerifyForgotOtpMutation();
  const [verifySellerOtp, { isLoading: verifyOtpSellerLoading }] =
    useVerifySellerOtpMutation();
  const [sendRegistrationEmail, { isLoading: registerLoading }] =
    useSentRegistationEmailMutation();
  // eslint-disable-next-line no-unused-vars
  const [sendSellerRegistrationEmail, { isLoading: registerSellerLoading }] =
    useSentSellerRegistationEmailMutation();
  const [forgotPasswordMail, { isLoading }] = useForgotPasswordMailMutation();
  const [resetPassword, { isLoading: resetLoading }] =
    useResetPasswordMutation();
  const [register, { isLoading: userRegisterLoading }] = useRegisterMutation();

  const handleResendOtp = async () => {
    try {
      let response;
      if (type === "forgot") {
        response = await handleApiRequest(() => forgotPasswordMail({ email }));
      } else if (type === "register") {
        response = await handleApiRequest(() => sendRegistrationEmail({ email }));
      } else if (type === "seller") {
        response = await handleApiRequest(() =>
          sendSellerRegistrationEmail({ email })
        );
      }
  
      if (response?.data?.succeeded === true) {
        toast.success("OTP Resent Successfully");
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (type === "forgot") {
        response = await handleApiRequest(() => forgotPasswordMail({ email }));
        console.log("response", response);
        if (response?.data?.succeeded === true) {
          setStep(2);
          toast.success("Otp Sent");
        }
      } else if (type === "register") {
        response = await handleApiRequest(() =>
          sendRegistrationEmail({ email })
        );
        if (response?.data?.succeeded === true) {
          setStep(2);
          toast.success("Otp Sent");
        }
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    console.log("OTP", otp.join(""));
    if (otp.length === 6) {
      try {
        let res;
        if (type === "seller") {
          await handleApiRequest(() =>
            verifySellerOtp({ email, otp: otp.join("") })
          );
          if (res && res?.data?.succeeded === true) {
            toast.success("Otp Verified");
            dispatch(setUserEmail(email));
            setStep(3);
          }
        } else if (type === "forgot") {
          res = await handleApiRequest(() =>
            verifyForgotOtp({ email, otp: otp.join("") })
          );
          if (res && res?.data?.succeeded === true) {
            toast.success("Otp Verified");
            dispatch(setUserEmail(email));
            setStep(3);
          }
        } else {
          res = await handleApiRequest(() =>
            verifyOtp({ email, otp: otp.join("") })
          );
          if (res && res?.data?.succeeded === true) {
            toast.success("Otp Verified");
            dispatch(setUserEmail(email));
            setStep(3);
          }
        }
      } catch (error) {
        console.log("error", error);
        toast.error(error?.message);
      }
    } else {
      toast.error("Please complete the OTP");
    }
  };

  const handleFinalSubmit = async (data) => {
    console.log("Submitting form data", data);
    if (data.password !== data.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    try {
      if (type === "forgot") {
        if (data?.password === data?.confirmPassword) {
          const res = await resetPassword({
            email: userEmail,
            newPassword: data?.password,
          }).unwrap();
          toast.success(res && res?.messages[0]);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          if (data?.password !== data?.confirmPassword) {
            toast.error("Passwords do not match.");
          } else {
            toast.error("Please fix the errors in the password field.");
          }
        }
      } else if (type === "register") {
        if (data?.password === data?.confirmPassword) {
          const { confirmPassword, ...dataToSend } = data;
          const res = await register({
            ...dataToSend,
            ContactEmail: userEmail,
          }).unwrap();
          toast.success(res && res?.messages[0]);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          if (data?.password !== data?.confirmPassword) {
            toast.error("Passwords do not match.");
          } else {
            toast.error("Please fix the errors in the password field.");
          }
        }
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error?.data?.messages[0] || error?.error);
    }
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <EmailStep
            email={email}
            setEmail={setEmail}
            onSubmit={handleEmailSubmit}
            isLoading={isLoading}
            registerLoading={registerLoading}
            registerSellerLoading={registerSellerLoading}
          />
        );
      case 2:
        return (
          <OtpStep
            otp={otp}
            setOtp={setOtp}
            onSubmit={handleOtpSubmit}
            onBack={handleBack}
            verifyLoading={verifyLoading}
            verifyForgotOtpLoading={verifyForgotOtpLoading}
            verifyOtpSellerLoading={verifyOtpSellerLoading}
            onResendOtp={handleResendOtp} 
          />
        );
      case 3:
        return (
          <FinalStep
            finalStepComponent={finalStepComponent}
            formData={formData}
            updateFormData={updateFormData}
            onSubmit={handleFinalSubmit}
            onBack={handleBack}
            resetLoading={resetLoading}
            userRegisterLoading={userRegisterLoading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h2 className="text-center mb-4">{finalStepProps?.title}</h2>
          <ProgressBar
          now={(step / 3) * 100}
          label={`step ${step} of 3`}
          className="mb-4"
       />
          {renderStep()}
        </Col>
      </Row>
    </Container>
  );
};

export default MultiStepForm;