import React, { useState } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";

const SearchBar = () => {
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState("Mumbai");
  const [isFocused, setIsFocused] = useState(false); // Track focus state

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const MAP_ENV = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${MAP_ENV}`
            );
            const data = await response.json();
            // fetch(
            //   `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            // )
            //   .then((response) => response.json())
            //   .then((data) => {
            //     const address = data.display_name;
            //     console.log("Address:", address);
            //   });

            if (data.status === "OK" && data.results.length > 0) {
              // Extract the most specific location details
              let detailedLocation = "";

              // Loop through the results to find a specific component
              for (let result of data.results) {
                for (let component of result.address_components) {
                  if (
                    component.types.includes("sublocality") ||
                    component.types.includes("neighborhood") ||
                    component.types.includes("premise") ||
                    component.types.includes("point_of_interest")
                  ) {
                    detailedLocation = component.long_name;
                    break;
                  }
                }
                if (detailedLocation) break;
              }

              // Fallback to formatted address if no specific details are found
              if (!detailedLocation) {
                detailedLocation = data.results[0].formatted_address;
              }

              setLocation(detailedLocation);
            } else {
              console.error("No results found for the location.");
              setLocation(`${latitude.toFixed(6)}, ${longitude.toFixed(6)}`);
            }
          } catch (error) {
            console.error("Error fetching location name:", error);
            setLocation(`${latitude.toFixed(6)}, ${longitude.toFixed(6)}`);
          }
        },
        (error) => {
          console.error("Error getting location:", error);
          alert("Unable to retrieve your location");
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    } else {
      alert("Geolocation is not supported by your browser");
    }
  };
  console.log("location", location);
  return (
    <Container>
      <Form onSubmit={handleSubmit} aria-label="location and search-box">
        <InputGroup
          className={`mb-3 width-100 ${isFocused ? "input-group-focused" : ""}`}
        >
          <InputGroup.Text className="bg-white border-end-0">
            <FaMapMarkerAlt
              color="#FC8019"
              onClick={getCurrentLocation}
              role="button"
            />
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Enter your location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            onFocus={handleFocus} // Add focus event
            onBlur={handleBlur}
          />
        </InputGroup>
      </Form>
    </Container>
  );
};

export default SearchBar;
