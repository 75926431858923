import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Image, Badge, Button, Card, ListGroup } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";

const ProductDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        const productData = {
          id,
          productName: "Sample Product",
          description: "This product is crafted with the finest materials to offer you the best experience.",
          price: 1999,
          rating: 4.5,
          ratingCount: 100,
          shopName: "BestShop",
          brand: "SampleBrand",
          color: "Red",
          imageUrl: "https://placehold.jp/500x500.png",
          relatedProducts: [
            { id: 2, name: "Related Product 1", imageUrl: "https://placehold.jp/150x150.png", price: 999 },
            { id: 3, name: "Related Product 2", imageUrl: "https://placehold.jp/150x150.png", price: 1499 },
            { id: 4, name: "Related Product 1", imageUrl: "https://placehold.jp/150x150.png", price: 999 },
            { id: 5, name: "Related Product 2", imageUrl: "https://placehold.jp/150x150.png", price: 1499 },
          ],
          isSubscribable: true,
          reviews: [
            {
              id: 1,
              username: "Iman Khan",
              rating: 4,
              comment: "Great product! Really enjoyed using it.",
              userImage: "https://placehold.jp/40x40.png",
              reviewImage: "https://placehold.jp/100x100.png", // Added review image
            },
            {
              id: 2,
              username: "buyer 2",
              rating: 5,
              comment: "Excellent quality and fast delivery. Highly recommend!",
              userImage: "https://placehold.jp/40x40.png",
              reviewImage: "https://placehold.jp/100x100.png", // Added review image
            },
            {
              id: 3,
              username: "Khan Shaikh",
              rating: 3,
              comment: "Good product but could be better in terms of build quality.",
              userImage: "https://placehold.jp/40x40.png",
              reviewImage: "https://placehold.jp/100x100.png", // Added review image
            },
          ],
        };
        setProduct(productData);
        setLoading(false);
      } catch (error) {
        setError("Error fetching product details.");
        setLoading(false);
      }
    };

    fetchProductDetail();
  }, [id]);

  const handleSubscribeClick = () => {
    navigate(`/subscribe/${product.id}`);
  };

  const calculateAverageRating = () => {
    const totalRating = product.reviews.reduce((sum, review) => sum + review.rating, 0);
    return (totalRating / product.reviews.length).toFixed(1);
  };

  if (loading) return <Loader />;
  if (error) return <Message variant="danger">{error}</Message>;

  return (
    <Container className="my-5 p-4" style={{ backgroundColor: "#f0f8ff", borderRadius: "10px" }}>
      {/* Product Image and Details */}
      <Row>
        <Col md={6} className="position-relative">
          <Image
            src={product.imageUrl}
            alt={product.productName}
            fluid
            className="product-image"
            style={{ transition: "transform 0.3s", cursor: "pointer", borderRadius: "10px" }}
            onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
            onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
          />
        </Col>
        {/* Product Details Section */}
        <Col md={6}>
          <Card className="mt-4 shadow-sm" style={{ borderRadius: "10px", transition: "transform 0.3s", backgroundColor: "#ffffff" }} onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.02)")} onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}>
            <Card.Body>
              <ListGroup variant="flush">

                <ListGroup.Item className="bg-transparent">
                  <h2>{product.productName}</h2>
                  <p className="text-muted">{product.description}</p>
                  <h5>
                    Color: <span style={{ textTransform: "capitalize" }}>{product.color}</span>
                  </h5>
                  <h4 className="text-danger">₹{product.price}</h4>
                  <div className="d-flex align-items-center my-3">
                    <Badge
                      bg={product.rating >= 3 ? "success" : "danger"}
                      className="me-2"
                      style={{ fontSize: "1.2rem" }}
                    >
                      {product.rating} ★
                    </Badge>
                    <span>({product.ratingCount} reviews)</span>
                  </div>
                  <p className="text-muted">Sold by: {product.shopName}</p>
                </ListGroup.Item>

                {/* About Brand Section */}
                <ListGroup.Item className="bg-transparent">
                  <h5>About the Brand</h5>
                  <p>{product.brand} is renowned for quality and innovation, ensuring each product meets the highest standards.</p>
                </ListGroup.Item>

                {/* About This Item Section */}
                <ListGroup.Item className="bg-transparent">
                  <h5>Product Features</h5>
                  <p>Explore the unique features of this product that make it stand out in the market.</p>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>

          {/* Action Buttons Section */}

          <div className="mt-4">
            <Card className="mt-4 shadow-sm" style={{ borderRadius: "10px", transition: "transform 0.3s", backgroundColor: "#ffffff" }} onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.02)")} onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}>
              <Card.Body>
                <h5>Actions</h5>
                <Button
                  variant="primary"
                  className="me-3 mb-2"
                  style={{ transition: "background-color 0.3s", width: "150px" }}
                  onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#004080")}
                  onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "")}
                >
                  Add to Cart
                </Button>
                <Button
                  variant="danger"
                  className="mb-2"
                  style={{ transition: "background-color 0.3s", width: "150px" }}
                  onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#a30000")}
                  onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "")}
                >
                  Buy Now
                </Button>
              </Card.Body>
            </Card>





            {/* Subscription Feature - Conditional Rendering */}
            {product.isSubscribable && (
              <Card className="mt-4 shadow-sm" style={{ borderRadius: "10px", transition: "transform 0.3s", backgroundColor: "#ffffff" }} onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.02)")} onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}>
                <Card.Body>
                  <h5>Subscribe for Regular Delivery</h5>
                  <p>Enjoy the convenience of regular deliveries. Customize your delivery schedule and payment options.</p>
                  <Button variant="success" onClick={handleSubscribeClick}>
                    Subscribe Now
                  </Button>
                </Card.Body>
              </Card>
            )}
          </div>
        </Col>
      </Row>

      {/* Related Products Section */}
      <Row className="mt-5">
        <h4>Related Products</h4>
        {product.relatedProducts.map((relatedProduct) => (
          <Col key={relatedProduct.id} sm={12} md={6} lg={4} xl={3} className="mb-3">
            <Card className="shadow-sm h-100" style={{ borderRadius: "10px", transition: "transform 0.3s, box-shadow 0.3s" }} onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")} onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}>
              <Card.Img variant="top" src={relatedProduct.imageUrl} />
              <Card.Body className="d-flex flex-column">
                <Card.Title>{relatedProduct.name}</Card.Title>
                <h5 className="text-danger mb-3">₹{relatedProduct.price}</h5>
                <Button variant="outline-primary" className="mt-auto w-100">
                  View Details
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Reviews Section */}
      <Row className="mt-5">
        <h4>Customer Reviews</h4>
        <Card
          className="mb-4 shadow-sm"
          style={{
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            transition: "transform 0.3s, box-shadow 0.3s",
            display: "flex",
            alignItems: "center",
            padding: "15px",
          }}
          onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
          onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
        >
          <Card.Body style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <h5>Average Rating</h5>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "24px", color: "#007bff", marginRight: "10px" }}>
                  {calculateAverageRating()} ★
                </span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "20px",
                    color: "#ffd700",
                  }}
                >
                  {'★'.repeat(Math.floor(calculateAverageRating()))}
                  {'☆'.repeat(5 - Math.floor(calculateAverageRating()))}
                </div>
              </div>
            </div>
            <div
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                backgroundColor: "#f8f8f8",
                border: "2px solid #007bff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ fontSize: "32px", color: "#007bff" }}>{calculateAverageRating()}</span>
            </div>
          </Card.Body>
        </Card>

        {product.reviews.map((review) => (
          <Col key={review.id} sm={12} md={6} lg={4} className="mb-3">
            <Card className="shadow-sm h-100" style={{ borderRadius: "10px", transition: "transform 0.3s, box-shadow 0.3s" }} onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.02)")} onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}>
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <Image src={review.userImage} roundedCircle width={40} height={40} className="me-3" />
                  <div>
                    <strong>{review.username}</strong>
                    <div className="text-warning">{'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}</div>
                  </div>
                </div>
                <Card.Text>{review.comment}</Card.Text>
                {/* Add review image if available */}
                {review.reviewImage && (
                  <Image src={review.reviewImage} className="w-100 rounded mb-2" />
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ProductDetailPage;
