import React from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import PaginateComponent from "../../components/Paginate";
import {
  useDeleteProductByIdMutation,
  useGetSellerProductsQuery,
} from "../../slices/productsApiSlice";

const ProductListScreen = () => {
  const { sellerId } = useParams();
  const navigate = useNavigate();
  const {
    data: products,
    isLoading,
    error,
    refetch,
  } = useGetSellerProductsQuery({
    entityID: sellerId || "116",
  });
  const [deleteProduct, { isLoading: loadingDeleteProduct }] =
    useDeleteProductByIdMutation();

  const handleCreateProduct = async () => {
    navigate("/admin/createproducts"); // Navigate to the correct URL
  };

  const handleDeleteProduct = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this product?")) {
        await deleteProduct(id).unwrap();
        toast.success("Product deleted successfully");
        refetch();
      }
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };
 
 
  return (
    <Container>
      <Row className="align-items-center">
        <Col>
          <h1>Products</h1>
        </Col>
        <Col className="text-end">
          <Button className="btn-sm m-3" onClick={handleCreateProduct}>
            <FaPlus /> Create Product
          </Button>
        </Col>
      </Row>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>PRICE</th>
                <th>CATEGORY</th>
                <th>STOCK</th>
                <th>RETURNABLE</th>
                <th>REPLACEMENT</th>
                <th>ADDED ON</th>
                <th>UPDATED ON</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {products?.data?.products?.length > 0 ? (
                products?.data?.products?.map((product) => (
                  <tr key={product?.product_id}>
                    <td>{product?.product_id}</td>
                    <td>{product?.productName ? product?.productName : "-"}</td>
                    <td>{`${product?.currency_mode} ${product?.price}`}</td>
                    <td>{product?.category}</td>
                    <td>{product?.stock_quantity}</td>
                    <td>{product?.can_be_returned ? "Yes" : "No"}</td>
                    <td>{product?.can_be_replaced ? "Yes" : "No"}</td>
                    <td>{new Date(product?.added_on).toLocaleDateString()}</td>
                    <td>
                      {new Date(product?.updated_on).toLocaleDateString()}
                    </td>
                    <td>
                      <LinkContainer
                        to={`/admin/product/${product?.product_id}/edit`}
                      >
                        <Button className="btn-sm mx-2">
                          <FaEdit />
                        </Button>
                      </LinkContainer>
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() => handleDeleteProduct(product?.product_id)}
                        disabled={loadingDeleteProduct}
                      >
                        <FaTrash color="white" />
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className="text-center">
                    No products found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {products?.data?.products?.length > 0 && (
            <PaginateComponent
              page={products.page}
              pages={products.pages}
              isAdmin={true}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default ProductListScreen;
