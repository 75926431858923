import React, { useRef, useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";

export const OtpStep = ({
  otp,
  setOtp,
  onSubmit,
  onBack,
  onResendOtp,
  length = 6,
}) => {
  const inputRefs = useRef([]);
  const [timer, setTimer] = useState(30); // Start timer from 30 seconds
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Initially disable resend button

  useEffect(() => {
    let countdown;
    if (isButtonDisabled && timer > 0) {
      // Decrease the timer every second if the button is disabled
      countdown = setTimeout(() => setTimer(timer - 1), 1000);
    } else if (timer === 0) {
      // Enable the button when timer reaches 0
      setIsButtonDisabled(false);
    }

    return () => clearTimeout(countdown); // Clear timer on component unmount
  }, [timer, isButtonDisabled]);

  const handleResendClick = () => {
    onResendOtp(); // Call the resend function
    setIsButtonDisabled(true); // Disable the button
    setTimer(30); // Reset the timer to 30 seconds
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    // Update the OTP value at the current index
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Automatically move to the next input if there's a next sibling
    if (element.value && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Handle backspace
    if (e.key === "Backspace" || e.key === "Delete") {
      if (!otp[index] && inputRefs.current[index - 1]) {
        // Focus the previous input if it's empty
        inputRefs.current[index - 1].focus();
      }
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>OTP</Form.Label>
        <div
  className="d-flex justify-content-center m-3"
  style={{ gridTemplateColumns: `repeat(${length}, 1fr)` }}
>

          {otp.map((data, index) => (
            <Form.Control
              key={index}
              type="text"
              maxLength="1"
              value={data}
              ref={(el) => (inputRefs.current[index] = el)}
              onChange={(e) => handleChange(e.target, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="text-center"
              style={{
                marginRight: index === length - 1 ? "0" : "10px",
                padding: "0.25rem 0.5rem",
                fontSize: "1rem",
                width: "40px",
                height: "40px",
                borderColor: "#18191a",
                borderRadius: "5px",
              }}
              required
            />
          ))}
        </div>
      </Form.Group>
      <div className="d-flex justify-content-between">
        <Button variant="secondary" onClick={onBack}>
          Back
        </Button>
        <Button variant="primary" type="submit">
          Verify
        </Button>
      </div>

      {/* Resend OTP Button */}
      <div>
        <br />
        <Button
          
          variant="link"
          onClick={handleResendClick}
          disabled={isButtonDisabled} // Disable based on timer state
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: isButtonDisabled ? "gray" : "#007bff",
            cursor: isButtonDisabled ? "not-allowed" : "pointer",
          }}
        >
          Resend OTP
        </Button>
      </div>

      {/* Show timer if button is disabled */}
      {isButtonDisabled && (
        <div className="text-center text-muted mt-2">
          You can resend OTP in {timer} seconds
        </div>
      )}
    </Form>
  );
}