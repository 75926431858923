import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Dropdown,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetProductsByNameQuery } from "../slices/productsApiSlice";

export const SearchDropDown = () => {
  const navigate = useNavigate();
  const { keyword: urlKeyword } = useParams();
  const [keyword, setKeyword] = useState(urlKeyword || ""); // Search keyword
  const [showDropdown, setShowDropdown] = useState(false); // To manage dropdown visibility
  const dropdownRef = useRef(null);
  const [getProducts, { isLoading, error }] = useLazyGetProductsByNameQuery();
  const [products, setProducts] = useState([]);

  const debouncedFetchProducts = debounce(async (searchKeyword) => {
    const { data } = await getProducts({ search: searchKeyword });
    setProducts(data?.data?.products || []);
  }, 1000);

  const handleInputChange = (e) => {
    const newKeyword = e.target.value;
    setKeyword(newKeyword);
    setShowDropdown(!!newKeyword);
    debouncedFetchProducts(newKeyword);
  };

  const handleSelectProduct = (productName) => {
    setKeyword(productName);
    setShowDropdown(false);
    navigate(`/search/${productName.trim()}`);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Container style={{ marginTop: "-10px" }}>
      <div ref={dropdownRef} className="position-relative">
        <InputGroup>
          <InputGroup.Text>
            <FaSearch size={15} />
          </InputGroup.Text>
          <Form.Control
            type="text"
            value={keyword}
            onChange={handleInputChange}
            onFocus={() => setShowDropdown(true)}
            placeholder="Search Products..."
          />
          {isLoading && (
            <InputGroup.Text>
              <Spinner animation="border" size="sm" />
            </InputGroup.Text>
          )}
        </InputGroup>

        {showDropdown && products?.length > 0 && (
          <Dropdown.Menu
            show
            style={{
              width: "100%",
              maxHeight: "200px",
              overflowY: "auto",
              position: "absolute",
              zIndex: 1000,
            }}
          >
            {products?.length > 0 ? (
              products?.map((product) => (
                <Dropdown.Item
                  key={product?.product_id}
                  onMouseDown={() => handleSelectProduct(product?.productName)}
                >
                  {product?.productName}
                </Dropdown.Item>
              ))
            ) : (
              <>
                <Dropdown.Item>No Search Results Found</Dropdown.Item>
              </>
            )}
          </Dropdown.Menu>
        )}
      </div>

      {/* {error && (
        <p className="text-white mt-2">{error?.data?.errors?.search?.[0]}</p>
      )} */}
    </Container>
  );
};
