import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useGetAllCategoryQuery } from "../slices/productsApiSlice";
import Loader from "./Loader";
import Message from "./Message";

const CategoriesTab = () => {
  const { data, isLoading, error } = useGetAllCategoryQuery();
  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Message variant={"error"}>
        {error?.data?.message || error?.error}
      </Message>
    );
  }

  const containerStyle = {
    backgroundColor: "#f0f8ff", // Pale blue background
    padding: "20px",
    borderRadius: "10px",
  };

  const cardStyle = {
    backgroundColor: "#ffffff", // White card background
    border: "none",
    borderRadius: "10px",
    overflow: "hidden",
    height: "100%",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
  };

  const titleStyle = {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#333",
    padding: "10px",
  };

  const hoverEffectStyle = {
    transform: "scale(1.05)",
    boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
  };

  return (
    <Container className="my-5" style={containerStyle}>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h3>Categories</h3>
        {/* <a href="/" className="text-decoration-none">
          All Categories in your city ›
        </a> */}
      </div>
      <p className="text-muted mb-4">
        Explore curated lists of top places in your city, based on trends
      </p>
      <Row>
        {data?.data?.categories?.map((category, index) => (
          <Col key={index} xs={12} sm={6} lg={3} className="mb-4">
            <Card
              className="h-100"
              style={{ ...cardStyle, ":hover": hoverEffectStyle }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Card.Img
                variant="top"
                src={"https://placehold.jp/296x200.png"}
                onError={(e) =>
                  (e.target.src =
                    "https://plus.unsplash.com/premium_photo-1679517155620-8048e22078b1?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
                }
              />
              <Card.Body
                className="d-flex flex-column align-items-center justify-content-center text-center"
                style={titleStyle}
              >
                <Card.Title>{category?.category}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CategoriesTab;
