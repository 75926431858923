import React from "react";
import { Badge, Container, Nav, Navbar } from "react-bootstrap";
import { FaShoppingCart, FaUser } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
// import { useLogoutMutation } from "../slices/usersApiSlice";
import { useSelector } from "react-redux";
import UserMenu from "./UserMenu";
export default function Header() {
  const { cartItems } = useSelector((state) => state.cart);
  const { userInformation } = useSelector((state) => state?.auth);
  return (
    <header style={{ position: "sticky", top: "0.1px", zIndex: "50" }}>
      <Navbar expand="md" collapseOnSelect className="navbar-glassmorph">
        <Container>
          <div className="navbar-custom" style={{ display: "flex" }}>
            <LinkContainer to="/">
              <Navbar.Brand>suuq</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar" />
          </div>

          <Navbar.Collapse id="basic-navbar">
            <Nav className="ms-auto">
              <LinkContainer to="/cart">
                <Nav.Link style={{ color: "#272727", fontWeight: "bold" }}>
                  <FaShoppingCart style={{ color: "#302f2f" }} />
                  Cart
                  {cartItems.length > 0 && (
                    <Badge pill bg="success" style={{ marginLeft: "5px" }}>
                      {cartItems.length}
                    </Badge>
                  )}
                </Nav.Link>
              </LinkContainer>
              {userInformation ? (
                <>
                  <UserMenu />
                </>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link style={{ color: "#000", fontWeight: "bold" }}>
                    <FaUser style={{ color: "#000" }} /> Login
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
