import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, ListGroup, Form, Container, Row, Col } from 'react-bootstrap';
import { addToCart, removeFromCart } from '../slices/cartSlice'; // Adjust the import path as needed

const Cart = () => {
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart); // Access cart items from Redux state

  // Calculate total, GST, and discounts
  const itemsPrice = cartItems.reduce((acc, item) => acc + item.price * item.qty, 0);
  const gst = itemsPrice * 0.18; // Assume GST is 18%
  const discount = itemsPrice > 1000 ? 100 : 0; // Example discount
  const totalPrice = itemsPrice + gst - discount;

  const handleRemoveFromCart = (id) => {
    dispatch(removeFromCart(id));
  };

  const handleQuantityChange = (item, qty) => {
    dispatch(addToCart({ ...item, qty: Number(qty) }));
  };

  return (
    <Container className="py-4">
      <Row>
        {/* Cart Items Section */}
        <Col md={8}>
          <h2 className="mb-4">Shopping Cart</h2>
          <ListGroup variant="flush">
            {cartItems.map((item) => (
              <ListGroup.Item key={item.product_id} className="d-flex align-items-center mb-3 p-3" style={{ border: '1px solid #ddd', borderRadius: '8px' }}>
                {/* Placeholder Image */}
                <img
                  src="https://via.placeholder.com/80"
                  alt={item.productName}
                  className="img-fluid rounded"
                  style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                />
                {/* Product Details */}
                <div className="ms-3 flex-grow-1">
                  <h5 className="mb-1">{item.productName}</h5>
                  <p className="mb-1 text-muted">₹{item.price} x {item.qty} = ₹{(item.price * item.qty).toFixed(2)}</p>
                  <small className="text-muted">Size: {item.size} | Color: {item.color}</small>
                </div>
                {/* Quantity Selector */}
                <Form.Control
                  as="select"
                  value={item.qty}
                  onChange={(e) => handleQuantityChange(item, e.target.value)}
                  className="ms-auto me-3"
                  style={{ width: '60px' }}
                >
                  {[...Array(10).keys()].map((x) => (
                    <option key={x + 1} value={x + 1}>
                      {x + 1}
                    </option>
                  ))}
                </Form.Control>
                {/* Remove Button */}
                <Button variant="outline-danger" onClick={() => handleRemoveFromCart(item.product_id)}>
                  Remove
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>

        {/* Summary Section */}
        <Col md={4}>
          <Card>
            <Card.Header className="bg-primary text-white">Price Summary</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col>Subtotal:</Col>
                    <Col>₹{itemsPrice.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>GST (18%):</Col>
                    <Col>₹{gst.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Discount:</Col>
                    <Col className="text-danger">-₹{discount.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Total:</Col>
                    <Col><strong>₹{totalPrice.toFixed(2)}</strong></Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
              {/* Checkout Button */}
              <Button variant="success" className="w-100 mt-3">
                Proceed to Checkout
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Cart;
