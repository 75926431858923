import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../slices/cartSlice";

const ProductGrid = ({ product }) => {
  const dispatch = useDispatch();

  // Access the cart state to determine if the product is in the cart
  const cartItems = useSelector((state) => state.cart.cartItems);
  const isInCart = cartItems.some((item) => item.product_id === product.product_id);

  // Handler to toggle the cart state (add or remove from cart)
  const toggleCartHandler = () => {
    if (isInCart) {
      dispatch(removeFromCart(product.product_id));
    } else {
      dispatch(addToCart({ ...product, qty: 1 }));
    }
  };

  return (
    <Link to={`/productDetail/${product.product_id}`} style={{ textDecoration: "none" }}>
      <Card
        className="product-card mb-4 shadow-sm"
        style={{ borderRadius: "8px", overflow: "hidden", position: "relative" }}
      >
        {/* Heart Icon for Adding to Cart */}
        <div
          onClick={(e) => {
            e.preventDefault(); // Prevent Link from being triggered when clicking the heart
            toggleCartHandler();
          }}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px", // Move to the right
            cursor: "pointer",
            color: isInCart ? "red" : "rgba(128, 128, 128, 0.7)", // Red if in cart, grey otherwise
            backgroundColor: "rgba(255, 255, 255, 0.9)", // Slight background for visibility
            borderRadius: "50%",
            padding: "8px",
            zIndex: 10,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s, box-shadow 0.2s", // Smooth hover transition
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "scale(1.1)"; // Scale up on hover
            e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)"; // Darker shadow on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "scale(1)"; // Reset scale
            e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)"; // Reset shadow
          }}
        >
          <FaHeart size={20} />
        </div>

        {/* Product Image on Top */}
        <Card.Img
          variant="top"
          src={"https://placehold.jp/222x193.png"}
          alt={product.productName}
          className="img-fluid"
        />

        {/* Product Details Below Image */}
        <Card.Body className="p-3">
          {/* Product Name */}
          <Card.Title className="text-truncate" style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
            {product.productName}
          </Card.Title>

          {/* Price */}
          <h5 className="text-danger mb-3" style={{ fontSize: "1.2rem", fontWeight: "600" }}>
            ₹{product.price}
          </h5>

          {/* Rating and Stars */}
          <div className="d-flex align-items-center mb-2">
            <span
              className={`badge ${product.rating >= 3 ? "bg-success" : "bg-danger"} me-2`}
              style={{ fontSize: "1rem" }}
            >
              {product.rating} ★
            </span>
            <span className="text-muted" style={{ fontSize: "0.9rem" }}>
              ({product.ratingCount} ratings)
            </span>
          </div>

          {/* Shop Name */}
          <Card.Text className="text-muted" style={{ fontSize: "1rem" }}>
            Sold by: {product.shopName}
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default ProductGrid;
