import React from "react";
import { Container } from "react-bootstrap";

const BookMarkScreen = () => {
  return (
    <Container>
      <h1>Bookmarks</h1>
    </Container>
  );
};

export default BookMarkScreen;
