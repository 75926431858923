import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  useForgotPasswordMailMutation,
  useSentRegistationEmailMutation,
  useVerifyForgotOtpMutation,
  useVerifyOtpMutation,
} from "../slices/usersApiSlice";
import OtpInput from "../components/OtpInput";
import {
  useSentSellerRegistationEmailMutation,
  useVerifySellerOtpMutation,
} from "../slices/sellerApiSlice";
import { handleApiRequest } from "../utils/helper";
import { setUserEmail } from "../slices/authSlice";
import { useDispatch } from "react-redux";
import { all } from "axios";

const OtpRegisterScreen = ({ onOtpSuccess, type }) => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const dispatch = useDispatch();

  const [verifyOtp, { isLoading: verifyLoading }] = useVerifyOtpMutation();
  const [verifyForgotOtp, { isLoading: verifyForgotOtpLoading }] =
    useVerifyForgotOtpMutation();
  const [verifySellerOtp, { isLoading: verifyOtpSellerLoading }] =
    useVerifySellerOtpMutation();
  const [sendRegistrationEmail, { isLoading: registerLoading }] =
    useSentRegistationEmailMutation();
  const [sendSellerRegistrationEmail, { isLoading: registerSellerLoading }] =
    useSentSellerRegistationEmailMutation();
  const [forgotPasswordMail, { isLoading }] = useForgotPasswordMailMutation();

  // Handle OTP input change
  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  // Function to handle OTP sending based on the type (seller, forgotPassword, normal registration)
  const handleSendOtp = async () => {
    try {
      let response;

      if (type === "seller") {
        response = await handleApiRequest(() =>
          sendSellerRegistrationEmail({ email })
        );
        setEmailSent(true);
        toast.success("OTP Sent");
      } else if (type === "forgotPassword") {
        response = await handleApiRequest(() => forgotPasswordMail({ email }));
        if (response?.data?.succeeded === true) {
          setEmailSent(true);
          toast.success("OTP Sent");
        }
      } else {
        response = await handleApiRequest(() =>
          sendRegistrationEmail({ email })
        );
        setEmailSent(true);
        toast.success("OTP Sent");
      }

      // Start timer and disable resend button
      setIsResendDisabled(true);
      setTimer(30);
    } catch (error) {
      console.log("error", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    let interval;
    if (emailSent && isResendDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsResendDisabled(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [emailSent, isResendDisabled]);


  // Function to verify OTP
  const handleVerifyOtp = async () => {
    if (otp.length === 6) {
      try {
        let res;
        if (type === "seller") {
          res = await handleApiRequest(() => verifySellerOtp({ email, otp }));
          if (res && res?.data?.succeeded === true) {
            toast.success("OTP Verified");
            onOtpSuccess();
            dispatch(setUserEmail(email));
          }
        } else if (type === "forgotPassword") {
          res = await handleApiRequest(() => verifyForgotOtp({ email, otp }));
          if (res && res?.data?.succeeded === true) {
            toast.success("OTP Verified");
            onOtpSuccess();
            dispatch(setUserEmail(email));
          }
        } else {
          res = await handleApiRequest(() => verifyOtp({ email, otp }));
          if (res && res?.data?.succeeded === true) {
            toast.success("OTP Verified");
            onOtpSuccess();
            dispatch(setUserEmail(email));
          }
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      toast.error("Please complete the OTP");
    }
  };

  return (
    <Form>
      <Form.Group controlId="email">
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>

      {/* Button to send OTP */}
      <Button
        variant="primary mt-2"
        onClick={handleSendOtp}
        disabled={
          !email || isLoading || registerLoading || registerSellerLoading
        }
      >
        Send OTP
      </Button>

      {emailSent && (
        <>
          {/* OTP Input component */}
          <OtpInput length={6} onOtpChange={handleOtpChange} />
          <Row className="mt-3">
            <Col xs={6}>
              {/* Button to verify OTP */}
              <Button
                variant="primary"
                onClick={handleVerifyOtp}
                disabled={
                  otp.length !== 6 ||
                  verifyLoading ||
                  verifyForgotOtpLoading ||
                  verifyOtpSellerLoading
                }
                block
              >
                Verify OTP and Proceed
              </Button>
            </Col>
            <Col xs={6} className="text-end">
              {/* Resend OTP Button */}
              <Button
                variant="secondary"
                onClick={handleSendOtp}
                disabled={isResendDisabled}
              >
                {isResendDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}
              </Button>

            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default OtpRegisterScreen;
