import React, { useRef, useState } from "react";

function OtpInput({ length, onOtpChange }) {
  const inputRef = useRef([]);
  const [otp, setOtp] = useState(Array(length).fill(""));

  const handleTextChange = (input, index) => {
    const newOtp = [...otp];
    newOtp[index] = input;
    setOtp(newOtp);

    // Automatically focus on the next input field if the current one is filled
    if (input.length === 1 && index < length - 1) {
      inputRef.current[index + 1].focus();
    }

    // Notify the parent component about the updated OTP
    onOtpChange(newOtp.join(""));
  };

  const handleKeyDown = (e, index) => {
    // Move to the previous input box on backspace/delete
    if (e.key === "Backspace" || e.key === "Delete") {
      if (otp[index] === "" && index > 0) {
        inputRef.current[index - 1].focus();
      }
    }
  };

  return (
    <div
      className="d-flex justify-content-center m-3"
      style={{ gridTemplateColumns: `repeat(${length}, 1fr)` }}
    >
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          value={otp[index]}
          onChange={(e) => handleTextChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(ref) => (inputRef.current[index] = ref)}
          className="form-control form-control-sm text-center"
          style={{
            marginRight: index === length - 1 ? "0" : "10px",
            padding: "0.25rem 0.5rem",
            fontSize: "1rem",
            width: "40px",
            height: "40px",
            borderColor: "#18191a",
          }}
        />
      ))}
    </div>
  );
}

export default OtpInput;
