import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SearchBar from "./SearchBar";
import { SearchDropDown } from "./SearchDropDown";

const HeroSection = () => {
  return (
    <Container>
      <Row className="align-items-center">
        <Col md={8}>
          <div className="mt-1">
            <SearchBar />
            <SearchDropDown />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HeroSection;
